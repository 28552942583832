import * as React from "react";
import { useState } from "react";
import { TextField } from "@fluentui/react/lib/TextField";
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, buildColumns, IColumn } from "@fluentui/react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Label, ILabelStyles } from "@fluentui/react/lib/Label";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { IIconProps } from "@fluentui/react";
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';
import { useId } from '@fluentui/react-hooks';

// function _onBreadcrumbItemClicked(ev: React.MouseEvent<HTMLElement>, item: IBreadcrumbItem): void {
//   console.log(`Breadcrumb item with key "${item.key}" has been clicked.`, ev);
// }

const styles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };
const calloutProps = { gapSpace: 0 };


export interface FolderPrefixList {
  Prefix: string;
  icon: string;
}

export interface FolderListProps {
  items: FolderPrefixList[];
  itemClicked: (data: any) => void;
  rawBreadCrumb?: any;
  _onBreadcrumbItemClicked: (data?: any) => void;
  uploadEmail: (data?: any, Prefix?: string) => void;
}
export interface FState {
  sortedItems?: any;
  columns?: any;
  BreadcrumbItem?: any;
  color: string;
  disabled?: boolean;
  checked?: boolean;
  selected?: any;
  curDirectory: string;
  UploadMessage?: string;
  pctAccessToken?: string;
  pctRefreshToken?: string;
  addInKey?: string;
  itemProps?: any;
  alert?: string;
}
export interface IButtonExampleProps {
  // These are set based on the toggles shown above the examples (not needed in real code)
  disabled?: boolean;
  checked?: boolean;
}

const volume0Icon: IIconProps = { iconName: "BulkUpload" };
const volume3Icon: IIconProps = { iconName: "CloudUpload" };
const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: "16px",
  },
  fileIconCell: {
    textAlign: "center",
    selectors: {
      "&:before": {
        content: ".",
        display: "inline-block",
        verticalAlign: "middle",
        height: "100%",
        width: "0px",
        visibility: "hidden",
      },
    },
  },
  fileIconImg: {
    verticalAlign: "middle",
    maxHeight: "16px",
    maxWidth: "16px",
  },
  controlWrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  exampleToggle: {
    display: "inline-block",
    marginBottom: "10px",
    marginRight: "30px",
  },
  selectionDetails: {
    marginBottom: "20px",
  },
});
const controlStyles = {
  root: {
    margin: "0 30px 20px 0",
    maxWidth: "300px",
  },
};

export default class SearchFolderList extends React.Component<FolderListProps, FState> {
  public rClient: any;
  private _allItems: any;
  constructor(props) {
    super(props);
    const { disabled, checked } = props;
    const columns: IColumn[] = [
      {
        key: "column1",
        name: "File Type",
        className: classNames.fileIconCell,
        iconClassName: classNames.fileIconHeaderIcon,
        ariaLabel: "Column operations",
        iconName: "Page",
        isIconOnly: true,
        fieldName: "icon",
        minWidth: 16,
        maxWidth: 16,
        // onColumnClick: this._onColumnClick,
        onRender: (item: FolderPrefixList) => (
          <i
            onClick={() => this.props.uploadEmail(item)}
            className="ms-Icon ms-font-m ms-Icon--CloudUpload"
          ></i>
        ),
      },
      {
        key: "column2",
        name: "Prefix",
        fieldName: "Prefix",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        // onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (item: FolderPrefixList) => {
          const split = item.Prefix.split("/");

          const Prefix = split.slice(0, split.length - 2).join("/") + "/";
          const tooltipId = useId('tooltip');

          return     <TooltipHost
          content={item.Prefix}
          // Give the user more time to interact with the tooltip before it closes
          closeDelay={500}
          id={tooltipId}
          calloutProps={calloutProps}
          styles={styles}
        ><span onDoubleClick={() => this.props.itemClicked({Prefix})}>{item.Prefix}</span> </TooltipHost>;
        },
      },
    ];

    this.state = {
      itemProps: this.props.items,
      columns,

      color: props.color,
      disabled,
      checked,
      selected: "Upload",
      curDirectory: "",
      UploadMessage: "",

      // columns: this._buildColumns(this.props.items,),
    };
    // this._allItems = this._generateDocuments();
  }

  //  _generateDocuments=()=> {
  //   const items:any = [];
  //   for (let i = 0; i < 500; i++) {
  //     const randomDate = _randomDate(new Date(2012, 0, 1), new Date());
  //     const randomFileSize = _randomFileSize();
  //     const randomFileType = _randomFileIcon();
  //     let fileName = _lorem(2);
  //     fileName = fileName.charAt(0).toUpperCase() + fileName.slice(1).concat(`.${randomFileType.docType}`);
  //     let userName = _lorem(2);
  //     userName = userName
  //       .split(' ')
  //       .map((name: string) => name.charAt(0).toUpperCase() + name.slice(1))
  //       .join(' ');
  //     items.push({
  //       key: i.toString(),
  //       name: fileName,
  //       value: fileName,
  //       iconName: randomFileType.url,
  //       fileType: randomFileType.docType,
  //       modifiedBy: userName,
  //       dateModified: randomDate.dateFormatted,
  //       dateModifiedValue: randomDate.value,
  //       fileSize: randomFileSize.value,
  //       fileSizeRaw: randomFileSize.rawSize,
  //     });
  //   }
  //   return items;
  // }
  componentWillReceiveProps(props): void {
    this.setState({ itemProps: props.items });
  }

  render() {
    const { columns, alert, itemProps } = this.state;

    return (
      <>
        <DetailsList
          items={itemProps}
          useFastIcons={true}
          //onRenderItemColumn={this._renderItemColumn}
          columns={columns}
          setKey="none"
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.justified}
          // selection={this._selection}
          selectionPreservedOnEmptyClick={true}
          // ariaLabelForSelectionColumn="Toggle selection"
          // ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          // checkButtonAriaLabel="select row"
          // onItemInvoked={this._onItemInvoked}
        />
      </>
    );
  }
}
