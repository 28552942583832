import * as React from "react";

// export interface HeroListItem {
//     icon: string;
//     primaryText: string;
// }

// export interface HeroListProps {
//     message: string;
//     items: HeroListItem[]
// }

import {
  Link,
  StackItem,
  MessageBar,
  MessageBarType,
  ChoiceGroup,
  IStackProps,
  MessageBarButton,
  Text,
  IChoiceGroupStyles,
} from "@fluentui/react";

export default class Alert extends React.Component<any> {
  render() {
    const { info, type } = this.props;

    let messageBar: any = "";
    if (type) {
      if (type === "successWithOutAction") {
        messageBar = (
          <div style={{ backgroundColor: "rgb(223,246,221)", color: "rgb(50,49,48)" }}>
            <MessageBar messageBarType={MessageBarType.success}>
              <div dangerouslySetInnerHTML={{ __html: info }} />
            </MessageBar>
          </div>
        );
      } else if (type === "WarningWithOutActionForAlreadyUploadedMail") {
        messageBar = (
          <div style={{ backgroundColor: "rgb(254,217,204)", color: "rgb(50,49,48)" }}>
            <MessageBar messageBarType={MessageBarType.severeWarning}>
              <div dangerouslySetInnerHTML={{ __html: info }} />
            </MessageBar>
          </div>
        );
      } else if (type === "Default") {
        messageBar = (
          <div style={{ backgroundColor: "rgb(243,242,241)", color: "rgb(50,49,48)" }}>
            <MessageBar>
              <div dangerouslySetInnerHTML={{ __html: info }} />
            </MessageBar>
          </div>
        );
      } else {
        messageBar = (
          <div style={{ backgroundColor: "rgb(243,242,241)", color: "rgb(50,49,48)" }}>
            <MessageBar>
              <div dangerouslySetInnerHTML={{ __html: info }} />
            </MessageBar>
          </div>
        );
      }
    } else {
      messageBar = (
        <div style={{ backgroundColor: "rgb(243,242,241)", color: "rgb(50,49,48)" }}>
          <MessageBar>
            <div dangerouslySetInnerHTML={{ __html: info }} />
          </MessageBar>
        </div>
      );
    }

    return messageBar;
  }
}
