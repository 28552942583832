import * as React from "react";
import axios from "axios";
import { Spinner, SpinnerType, Stack, TagItem, TeachingBubble } from "office-ui-fabric-react";

import OfficeAddinMessageBar from "./OfficeAddinMessageBar";

import { writeFileNamesToWorksheet, logoutFromO365, signInO365 } from "../../../utilities/office-apis-helpers";
import { getDirectoryList, findEmail, searchEmail, UpdateEmail, tags } from "../Action/Directory";
import FolderList from "./FolderList";
import SearchFolderList from "./SearchFolderList";
import { TagMenu } from "./Tag";
import { ActionButton } from "@fluentui/react/lib/Button";
import { Text, ITextProps } from "@fluentui/react/lib/Text";
import { CommandButton } from "@fluentui/react/lib/Button";

import { Label, ILabelStyles } from "@fluentui/react/lib/Label";
import { SearchBox, ISearchBoxStyles } from "@fluentui/react/lib/SearchBox";
import { Checkbox } from '@fluentui/react';
//const accessUrl = "http://localhost:8000";
const accessUrl = "https://api.fileit.paharpur.co.in";
const searchBoxStyles: Partial<ISearchBoxStyles> = { root: { width: "100%" } };

const labelStyles: Partial<ILabelStyles> = {
  root: { margin: "10px 0", selectors: { "&:not(:first-child)": { marginTop: 24 } } },
};

import FormData from "form-data";
import Alert from "./Alert";
export interface AppProps {
  //   title: string;
  //   isOfficeInitialized: boolean;
}

export interface AppState {
  authStatus?: string;
  fileFetch?: string;
  headerMessage?: string;
  errorMessage?: string;
  initFolderList?: any;
  FolderList?: any;
  panHeight?: any;
  rawBreadCrumb?: any;
  curDirectory?: string;
  accessToken?: string;
  o365RefreshToken?: string;
  pctAccessToken?: string;
  pctRefreshToken?: string;
  alert?: string;
  alertType?: string;
  selected?: string;
  UploadStatus?: number;
  folderSearchItem?: any;
  searchCompAppr?: boolean;
  selectedTag?: any;
  deletedTag?: string;
  tagItems?: any;
  searchType: string;
  searchKeyType: any;
  searchPlaceholder:string;
}

export default class Main extends React.Component<AppProps, AppState> {
  constructor(props, context) {
    super(props, context);

    const searchKeyType: any = {
      // items: [
      //   {
      //     key: "globalFolderSearch",
      //     text: "All folders",
      //     onClick: () => this.searchType("All folders"),
      //   },
      //   {
      //     key: "LocalFolderSearch",
      //     text: "Current folder",
      //     onClick: () => this.searchType("Current folder"),
      //   },
      // ],
    };
    this.state = {
      searchKeyType,
      searchPlaceholder:"All folders",
      searchCompAppr: true,
      searchType: "All folders",
      authStatus: "notLoggedIn",
      fileFetch: "notFetched",
      headerMessage: "Welcome To File.it",
      errorMessage: "",
      panHeight: {
        root: {
          height: "450px",
          overflowY: "auto",
          overflowX: "auto",
        },
      },
      FolderList: [],
      initFolderList: [],
      rawBreadCrumb: "",
      curDirectory: "",
      pctAccessToken: localStorage.PctData ? JSON.parse(localStorage.PctData).oauthToken.AccessToken : "",
      pctRefreshToken: localStorage.PctData ? JSON.parse(localStorage.PctData).oauthToken.RefreshToken : "",
      accessToken: localStorage.data ? JSON.parse(localStorage.data).accessToken : "",
      alert: "",
      UploadStatus: 0,
      folderSearchItem: [],
      tagItems: [
        // { key: "PO", text: "PO" },
        // { key: "OA", text: "OA" },
        // { key: "PI", text: "PI" },
        // { key: "BM", text: "BM" },
        // { key: "CS", text: "CS" },
        // { key: "PS", text: "PS" },
        // { key: "ENQ", text: "ENQ" },
        // { key: "PRO", text: "PRO" },
        // { key: "WS", text: "WS" },
        // { key: "OTN", text: "OTN" },
        // { key: "LR", text: "LR" },
        // { key: "PAC", text: "PAC" },
        // { key: "INV", text: "INV" },
        // { key: "TNG", text: "TNG" },
        // { key: "OSR", text: "OSR" },
        // { key: "EWB", text: "EWB" },
        // { key: "IMP", text: "IMP" },
        // { key: "OTH", text: "OTH" },
        
      ],
      deletedTag: "",
      selectedTag: [],
    };

    // Bind the methods that we want to pass to, and call in, a separate
    // module to this component. And rename setState to boundSetState
    // so code that passes boundSetState is more self-documenting.
    this.boundSetState = this.setState.bind(this);
    this.setToken = this.setToken.bind(this);
    this.displayError = this.displayError.bind(this);
    this.login = this.login.bind(this);
    this.init();
    this.doSomething(Office.context.mailbox.item);
    this.tags()
  }

  async init() {
    if (!Office.context.mailbox) {
      console.log("Run inside Outlook to be able to use it.");
      return;
    }
    console.log("Running in Office Add-in");

    // Set up ItemChanged event
    Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, this.selectedMailItemChanged);
    console.log("Item Change event registered.");
  }
  selectedMailItemChanged = async (eventArgs) => {
    console.log("Another email message selected" + eventArgs);

    if (Office.context.mailbox.item != null) {
      this.doSomething(Office.context.mailbox.item);
    } else {
      console.log("No email is selected.");
      Office.context.mailbox.removeHandlerAsync(Office.EventType.ItemChanged, {}, function () {
        console.log("Item Change event unregistered.");
        Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, this.selectedMailItemChanged);
        console.log("Item Change event re-registered.");
      });
    }
  };

  async doSomething(item) {
    console.log(item)
    this.setState({ tagItems: [] });
    this.setState({
      tagItems: [
        // { key: "PO", text: "PO" },
        // { key: "OA", text: "OA" },
        // { key: "PI", text: "PI" },
        // { key: "BM", text: "BM" },
        // { key: "CS", text: "CS" },
        // { key: "PS", text: "PS" },
        // { key: "ENQ", text: "ENQ" },
        // { key: "PRO", text: "PRO" },
        // { key: "WS", text: "WS" },
        // { key: "OTN", text: "OTN" },
        // { key: "LR", text: "LR" },
        // { key: "PAC", text: "PAC" },
        // { key: "INV", text: "INV" },
        // { key: "TNG", text: "TNG" },
        // { key: "OSR", text: "OSR" },
        // { key: "EWB", text: "EWB" },
        // { key: "IMP", text: "IMP" },
        // { key: "GEN", text: "GEN" },
        // { key: "OTH", text: "OTH" },
      ],
      selectedTag: [],
      deletedTag: "",
    });
    this.findEmailIsExistOrNot(this.state.pctAccessToken, this.state.pctRefreshToken, item);
    // console.log( item.conversationId);
    // UpdateEmail(
    //   this.state.pctAccessToken,
    //   this.state.pctRefreshToken,
    //   item.internetMessageId,
    //   item.conversationId,
    //   this.state.accessToken,
    //   Office.context.mailbox.item.normalizedSubject
    // ).then(
    //   (onResolved) => {
    //           if (onResolved.UploadedItemsCount > 0) {
    //       this.setState({ alert: "Conversation is updated " + item.subject, alertType: "successWithOutAction" });
    //     }
    //   },
    //   () => {}
    // );
  }

  /**
   * END func doSomething
   */
  /**
   * Find Email
   * [Check selected email is already exist or not in our database ]
   *
   */
  /**
   *
   * @param pctAccessToken // Paharpur authentication access token
   * @param pctRefreshToken // Paharpur authentication refresh token
   * @param item  // outlook on change selected Item details
   *
   */

  /**
   * we replace / and + sign from item.itemId
   */
  async findEmailIsExistOrNot(pctAccessToken, pctRefreshToken, item) {
    findEmail(pctAccessToken, pctRefreshToken, item.internetMessageId).then(
      async (data) => {
        if (data.data.data.length > 0) {
          let UploadedList = "Already Uploaded ";
          for await (const upMess of data.data.data) {
            UploadedList += `<p> Folder :<b>${upMess.prefix}</b> Date :<b>${upMess.uploadedDateTime.split("T")[0]
              }</b></p>`;
          }
          this.setState({ alert: UploadedList, alertType: "WarningWithOutActionForAlreadyUploadedMail" });
        } else {
          this.setState({ alert: item.subject, alertType: "Default" });
        }
      },
      () => { }
    );
  }

  /**
   *
   *
   */

  uploadEmail = async (item: any) => {
    try {
      this.setState({ selected: item });
      const Prefix = item.Prefix;
      let form = new FormData();

      form.append("prefix", Prefix);
      form.append("accessToken", this.state.accessToken);
      form.append("conversationId", Office.context.mailbox.item.conversationId);

      const tags = JSON.stringify(this.state.selectedTag);
      const configUpload: any = {
        method: "POST",
        url: `${accessUrl}/email/post`,

        headers: {
          Authorization: `${this.state.pctAccessToken} ${this.state.pctRefreshToken}`,
        },
        data: {
          prefix: Prefix,
          accessToken: this.state.accessToken,
          conversationId: Office.context.mailbox.item.conversationId,
          normalizedSubject: Office.context.mailbox.item.normalizedSubject,
          tags,
        },
      };

      this.setState({ UploadStatus: this.state.UploadStatus + 1 });
      axios(configUpload)
        .then((response) => {
          const { data } = response;
          this.setState({ UploadStatus: this.state.UploadStatus - 1 });
          this.setState({
            alert: data.message,
          });
          setTimeout(() => {
            this.setState({ alert: "" });
          }, 4000);
        })
        .catch((error: any) => {
          this.setState({ alert: "Session is timed out. Please re-login" });
          this.setState({ UploadStatus: this.state.UploadStatus - 1 });

          setTimeout(() => {
            this.setState({ alert: "" });
          }, 7000);

          console.log(error);
        });
    } catch (error) { }
  };

  /*
        Properties
    */

  accessToken: string;

  componentDidMount(): void {
    if (localStorage.data && localStorage.PctData) {
      this.setState({ authStatus: "LogInSuccessfully" });
      this.setState({
        panHeight: {
          root: {
            height: document.getElementById("container").clientHeight + 80 + "px",
            overflowY: "auto",
            overflowX: "auto",
          },
        },
        pctAccessToken: localStorage.PctData ? JSON.parse(localStorage.PctData).oauthToken.AccessToken : "",
        pctRefreshToken: localStorage.PctData ? JSON.parse(localStorage.PctData).oauthToken.RefreshToken : "",
        accessToken: localStorage.data ? JSON.parse(localStorage.data).accessToken : "",
      });

      this.getDirectoryList();
    }
  }
  tags = async () => {
    const { data } = await tags(this.state.pctAccessToken, this.state.pctRefreshToken);
    console.log(data)
    this.setState({ tagItems: data.data.Tags });
  };
  /**
   * Search Folder
   */

 
  searchDirectory = async (searchText: any) => {
    const { data } = await searchEmail(this.state.pctAccessToken, this.state.pctRefreshToken, searchText);
    this.setState({ folderSearchItem: data.data });
  };
  // searchType = (type: any) => {
  //   console.log(type);
  //   this.setState({ searchType: type });
  // };

  searchType = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
    ev.persist()
  
    this.setState({ searchType: isChecked?'Current folder':'All folders' });
    this.setState({ searchPlaceholder: isChecked?'Current folder':'All folders' });
    
  };

  // searchType _onChange(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
  //   console.log(`The option has been changed to ${isChecked}.`);
  // }
  

  searchFolders = (text: string) => {
    const type = this.state.searchType;
    if (type === "All folders") {
      this.searchDirectory(text);
    } else {
      this.onChangeLocalSearch(text);
    }
  };

  private onChangeLocalSearch = (text: string): void => {
    const search = this.state.FolderList.filter((i) => i.Prefix.toLowerCase().indexOf(text) > -1);
    this.setState({
      FolderList: text ? (search.length > 0 ? search : this.state.initFolderList) : this.state.initFolderList,
    });
  };

  _onBreadcrumbItemClicked = async (e) => {
    this.itemClicked({ Prefix: e });
  };
  itemClicked = async (val: { Prefix?: string }) => {
    this.setState({ folderSearchItem: [] });
    const result = await getDirectoryList(this.state.pctAccessToken, this.state.pctRefreshToken, val.Prefix);

    if (result.CommonPrefixes.length > 0) {
      this.setState({
        FolderList: result.CommonPrefixes,
        initFolderList: result.CommonPrefixes,
        rawBreadCrumb: result.Prefix,
      });
    }
  };
  getDirectoryList = async () => {
    try {
      const result = await getDirectoryList(this.state.pctAccessToken, this.state.pctRefreshToken, "/");

      if (result.CommonPrefixes.length > 0) {
        this.setState({
          FolderList: result.CommonPrefixes,
          initFolderList: result.CommonPrefixes,
          rawBreadCrumb: result.Prefix,
        });
      }
    } catch (error) { }
  };

  /*
        Methods
    */

  boundSetState: () => {};

  setToken = (accesstoken: any) => {
    this.accessToken = accesstoken;
    this.setState({
      pctAccessToken: accesstoken.pctAccessToken,
      pctRefreshToken: accesstoken.pctRefreshToken,
      accessToken: accesstoken.accessToken,
    });
    this.getDirectoryList();
  };

  displayError = (error: string) => {
    this.setState({ errorMessage: error });
  };

  // Runs when the user clicks the X to close the message bar where
  // the error appears.
  errorDismissed = () => {
    this.setState({ errorMessage: "" });

    // If the error occured during a "in process" phase (logging in or getting files),
    // the action didn't complete, so return the UI to the preceding state/view.
    this.setState((prevState) => {
      if (prevState.authStatus === "loginInProcess") {
        return { authStatus: "notLoggedIn" };
      } else if (prevState.fileFetch === "fetchInProcess") {
        return { fileFetch: "notFetched" };
      }
      return null;
    });
  };

  login = async () => {
    await signInO365(this.boundSetState, this.setToken, this.displayError);
  };

  logout = async () => {
    await logoutFromO365(this.boundSetState, this.displayError);
  };

  /**
   *
   */
  searchComponentAppearing = () => {
    this.setState({ searchCompAppr: !this.state.searchCompAppr, folderSearchItem: [] });
  };
  /**
   *
   */

  addedCategory = (result) => {
    this.setState({ selectedTag: result });
  };
  /**
   *
   * @param result
   */
  deleteTag = (result) => {
    this.setState({ deletedTag: result });
  };

  render() {
    //  const { title, isOfficeInitialized } = this.props;
    const {
      searchKeyType,
      alert,
      alertType,
      folderSearchItem,
      searchCompAppr,
      selectedTag,
      tagItems,
      deletedTag,
      searchType,
    } = this.state;

    // if (!isOfficeInitialized) {
    //   return (
    //     <Progress title={title} logo="assets/icon-128.png" message="Please sideload your add-in to see app body." />
    //   );
    // }

    // Set the body of the page based on where the user is in the workflow.
    let body;

    if (this.state.authStatus === "notLoggedIn") {
      body = (
        <>
          {/* <Header logo="assets/pctlLogo1.jpg" title={this.props.title} message={this.state.headerMessage} />
          <StartPageBody login={this.login} /> */}
        </>
      );
    } else if (this.state.authStatus === "loginInProcess") {
      body = <Spinner className="spinner" type={SpinnerType.large} label="Please sign-in on the pop-up window." />;
    } else {
      body =
        this.state.FolderList.length === 0 ? (
          <>
            <Spinner className="spinner" type={SpinnerType.large} label="Please wait" />{" "}
          </>
        ) : (
          <Stack horizontal styles={{ root: { height: "100%" } }}>
            <Stack.Item styles={{ root: { width: "100%" } }}>
              {/*Right column*/}

              <Stack.Item verticalFill>
                <Stack.Item>
                  {/* <div onClick={this.logout}>LogOut</div> */}
                  <div>
                    {this.state.UploadStatus > 0 ? (
                      <Spinner
                        label={this.state.UploadStatus.toString() + " file(s) is uploading"}
                        ariaLive="assertive"
                        labelPosition="right"
                      />
                    ) : (
                      ""
                    )}
                    {alert ? <Alert info={alert} type={alertType} /> : ""}
                  </div>
                </Stack.Item>
                <Stack.Item verticalFill>
                  <TagMenu tItems={tagItems} deleteParams={deletedTag} sendDataToParent={this.addedCategory} />

                  {/* <ActionButton onClick={this.searchComponentAppearing}>
                   <i className="ms-Icon ms-font-m ms-Icon--Search"></i>
                  </ActionButton> */}
                  <ActionButton href="https://fileit.paharpur.co.in/" target="_blank" title="let us bing!"  > Web App  </ActionButton>
                  {searchCompAppr ? (
                    <>
                      <Stack horizontal={true}>
                        {/* <CommandButton text={searchType} menuProps={searchKeyType} /> */}
                        <Checkbox  onChange={this.searchType} />

                        {/* <SearchBox
                          styles={searchBoxStyles}
                          placeholder="Search"
                          onEscape={() => {
                            this.setState({ folderSearchItem: [] });
                          }}
                          onClear={() => {
                            this.setState({ folderSearchItem: [] });
                          }}
                          onChange={(_, newValue) => {
                            newValue == "" ? "" : this.setState({ folderSearchItem: [] });

                            if (newValue !== undefined && newValue.length > 2) {
                              this.searchDirectory(newValue);
                            }
                          }}
                          onSearch={(newValue) => this.searchDirectory(newValue)}
                        /> */}
                        <SearchBox
                          styles={searchBoxStyles}
                          placeholder={this.state.searchPlaceholder}
                          onEscape={() => {
                            this.setState({ folderSearchItem: [], FolderList: this.state.initFolderList });
                          }}
                          onClear={() => {
                            this.setState({ folderSearchItem: [], FolderList: this.state.initFolderList });
                          }}
                          onChange={(_, newValue) => {
                            newValue == ""
                              ? ""
                              : this.setState({ folderSearchItem: [], FolderList: this.state.initFolderList });

                            if (newValue !== undefined && newValue.length > 2) {
                              this.searchFolders(newValue);
                            }
                          }}
                          onSearch={(newValue) => this.searchFolders(newValue)}
                        />
                      </Stack>
                    </>
                  ) : (
                    ""
                  )}

                  {selectedTag.length > 0 ? (
                    <div
                      style={{
                        width: "300px",
                        overflowY: "scroll",
                      }}
                    >
                      {" "}
                      {selectedTag.map((item, i) => {
                        return (
                          <Text
                            key={i}
                            style={{
                              backgroundColor: "blue",
                              color: "white",
                              marginLeft: "2px",
                              padding: "5px",
                              borderRadius: "2px",
                            }}
                          >
                            {item}{" "}
                            <a onClick={() => this.deleteTag(item)}>
                              <i className="ms-Icon ms-font-m ms-Icon--Cancel"></i>
                            </a>{" "}
                          </Text>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}

                  {folderSearchItem.length > 0 ? (
                    <SearchFolderList
                      items={this.state.folderSearchItem}
                      itemClicked={this.itemClicked}
                      rawBreadCrumb={this.state.rawBreadCrumb}
                      _onBreadcrumbItemClicked={this._onBreadcrumbItemClicked}
                      uploadEmail={this.uploadEmail}
                    />
                  ) : (
                    ""
                  )}
                  <FolderList
                    items={this.state.FolderList}
                    itemClicked={this.itemClicked}
                    rawBreadCrumb={this.state.rawBreadCrumb}
                    _onBreadcrumbItemClicked={this._onBreadcrumbItemClicked}
                    uploadEmail={this.uploadEmail}
                  />
                </Stack.Item>
              </Stack.Item>
            </Stack.Item>
          </Stack>
        );

      //<FolderList items={this.state.FolderList} />
    }

    return (
      <div>
        {this.state.errorMessage ? (
          <OfficeAddinMessageBar onDismiss={this.errorDismissed} message={this.state.errorMessage + " "} />
        ) : null}

        <div className="ms-welcome" id="container">
          {body}
        </div>
      </div>
    );
  }
}
