import * as React from "react";
import { Spinner, SpinnerType, Stack, TagItem, TeachingBubble } from "office-ui-fabric-react";
import Header from "./Header";
import Progress from "./Progress";
import StartPageBody from "./StartPageBody";
import OfficeAddinMessageBar from "./OfficeAddinMessageBar";
import { writeFileNamesToWorksheet, logoutFromO365, signInO365 } from "../../../utilities/office-apis-helpers";
import { getDirectoryList } from "../Action/Directory";

import Main from "./Main";

export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

export interface AppState {
  authStatus?: string;
  fileFetch?: string;
  headerMessage?: string;
  errorMessage?: string;
  panHeight?: any;
  pctAccessToken?: string;
  pctRefreshToken?: string;
  accessToken?: string;
}

export default class App extends React.Component<AppProps, AppState> {
  constructor(props, context) {
    super(props, context);

    this.state = {
      // searchCompAppr: false,
      authStatus: "notLoggedIn",
      fileFetch: "notFetched",
      headerMessage: "Welcome To File.it",
      errorMessage: "",
      panHeight: {
        root: {
          height: "450px",
          overflowY: "auto",
          overflowX: "auto",
        },
      },
    };

    // Bind the methods that we want to pass to, and call in, a separate
    // module to this component. And rename setState to boundSetState
    // so code that passes boundSetState is more self-documenting.
    this.boundSetState = this.setState.bind(this);
    this.setToken = this.setToken.bind(this);
    this.displayError = this.displayError.bind(this);
    this.login = this.login.bind(this);
    // this.init();
    // this.doSomething(Office.context.mailbox.item);
  }

  componentDidMount(): void {
    if (localStorage.data && localStorage.PctData) {
      this.setState({ authStatus: "LogInSuccessfully" });
      this.setState({
        panHeight: {
          root: {
            height: document.getElementById("container").clientHeight + 80 + "px",
            overflowY: "auto",
            overflowX: "auto",
          },
        },
      });
      this.getDirectoryList();
    }
  }
  getDirectoryList = async () => {
    try {
      const result = await getDirectoryList(JSON.parse(localStorage.PctData).oauthToken.AccessToken,JSON.parse(localStorage.PctData).oauthToken.RefreshToken, "/");
    } catch (error) {
      this.setState({ authStatus: "notLoggedIn" });
    }
  };

  boundSetState: () => {};

  setToken = (accesstoken: any) => {
    this.setState({
      pctAccessToken: accesstoken.pctAccessToken,
      pctRefreshToken: accesstoken.pctRefreshToken,
      accessToken: accesstoken.accessToken,
    });
    // this.getDirectoryList();
  };

  displayError = (error: string) => {
    this.setState({ errorMessage: error });
  };

  // Runs when the user clicks the X to close the message bar where
  // the error appears.
  errorDismissed = () => {
    this.setState({ errorMessage: "" });

    // If the error occured during a "in process" phase (logging in or getting files),
    // the action didn't complete, so return the UI to the preceding state/view.
    this.setState((prevState) => {
      if (prevState.authStatus === "loginInProcess") {
        return { authStatus: "notLoggedIn" };
      } else if (prevState.fileFetch === "fetchInProcess") {
        return { fileFetch: "notFetched" };
      }
      return null;
    });
  };

  login = async () => {
    await signInO365(this.boundSetState, this.setToken, this.displayError);
  };

  logout = async () => {
    await logoutFromO365(this.boundSetState, this.displayError);
  };

  render() {
    const { title, isOfficeInitialized } = this.props;
    // const { alert, alertType, folderSearchItem, searchCompAppr, selectedTag, tagItems, deletedTag } = this.state;

    if (!isOfficeInitialized) {
      return (
        <Progress title={title} logo="assets/icon-128.png" message="Please sideload your add-in to see app body." />
      );
    }

    // Set the body of the page based on where the user is in the workflow.
    let body;

    if (this.state.authStatus === "notLoggedIn") {
      body = (
        <>
          <Header logo="assets/pctlLogo1.jpg" title={this.props.title} message={this.state.headerMessage} />
          <StartPageBody login={this.login} />
        </>
      );
    } else if (this.state.authStatus === "loginInProcess") {
      body = <Spinner className="spinner" type={SpinnerType.large} label="Please sign-in on the pop-up window." />;
    } else {
      body = (
        <>
          <Stack horizontal styles={{ root: { height: "100%" } }}>
            <Stack.Item styles={{ root: { width: "100%" } }}>
              <Stack.Item verticalFill>
                <Stack.Item verticalFill styles={this.state.panHeight}>
                  <Main />
                </Stack.Item>
                <Stack.Item align="center">
                  <div onClick={this.logout}>Logout</div>
                </Stack.Item>
              </Stack.Item>
            </Stack.Item>
          </Stack>
        </>
      );

      //<FolderList items={this.state.FolderList} />
    }

    return (
      <div>
        {this.state.errorMessage ? (
          <OfficeAddinMessageBar onDismiss={this.errorDismissed} message={this.state.errorMessage + " "} />
        ) : null}

        <div className="ms-welcome" id="container">
          {body}
        </div>
      </div>
    );
  }
}
