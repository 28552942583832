import { AppState } from '../src/taskpane/components/App';
import { AxiosResponse } from 'axios';
import axios from "axios";
//const accessUrl = "http://localhost:8000";
const accessUrl = "https://api.fileit.paharpur.co.in";
/*
     Interacting with the Office document
*/

export const writeFileNamesToWorksheet = async (result: AxiosResponse,
    displayError: (x: string) => any) => {

    return Excel.run((context: Excel.RequestContext) => {
        const sheet = context.workbook.worksheets.getActiveWorksheet();

        const data = [
            [result.data.value[0].name],
            [result.data.value[1].name],
            [result.data.value[2].name]];

        const range = sheet.getRange('B5:B7');
        range.values = data;
        range.format.autofitColumns();

        return context.sync();
    })
        .catch((error) => {
            displayError(error.toString());
        });
};

/*
    Managing the dialogs.
*/

let loginDialog: Office.Dialog;
const dialogLoginUrl: string = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/login.html';

export const signInO365 = async (setState: (x: AppState) => any,
    setToken: (x: string) => any,
    displayError: (x: string) => any) => {

    setState({ authStatus: 'loginInProcess' });

    await Office.context.ui.displayDialogAsync(
        dialogLoginUrl,
        { height: 40, width: 30 },
        (result) => {
            if (result.status === Office.AsyncResultStatus.Failed) {
                displayError(`${result.error.code} ${result.error.message}`);
            }
            else {
                loginDialog = result.value;
                loginDialog.addEventHandler(Office.EventType.DialogMessageReceived, function (arg: any) {
                    let messageFromDialog = JSON.parse(arg.message);
                    if (messageFromDialog.status === 'success') {
                        /**
                         * Get user details from O365
                         */
                        const graphEndpoint = "https://graph.microsoft.com/v1.0/me";

                        const detailsMe: any = {
                            method: "GET",
                            headers: {
                                Authorization: "Bearer " + messageFromDialog.result,

                                'Access-Control-Allow-Origin': '*',
                                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',

                            },
                            url: graphEndpoint
                        };
                        /**
                         * 
                         */
                        axios(detailsMe)
                            .then((dataO365) => {
                                /**
                                 * set O365 user to localStorage
                                 */

                                let localData = dataO365.data
                                localData.accessToken = messageFromDialog.result
                                localStorage.setItem("data", JSON.stringify(localData))
                                /**
                                 * Get @userPrincipalName  as email get for PCT local verification
                                 */
                                const { userPrincipalName }: any = dataO365.data

                                const LoginJson: any = {
                                    method: "POST",
                                    url: `${accessUrl}/auth/verify-email`,
                                    data: {
                                        email: userPrincipalName,
                                        empCode: userPrincipalName,
                                        appId: "7",
                                    },
                                    headers: {

                                        'Access-Control-Allow-Origin': '*',
                                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',

                                    },
                                };
                                axios(LoginJson)
                                    .then((data) => {

                                        loginDialog.close();
                                        localStorage.setItem("PctData", JSON.stringify(data.data))

                                        localData.pctAccessToken = data.data.oauthToken.AccessToken
                                        localData.pctRefreshToken = data.data.oauthToken.RefreshToken
                                        setToken(localData);
                                        setState({
                                            authStatus: 'loggedIn',
                                            headerMessage: 'Get Data'
                                        });
                                    })
                                    .catch((err) => {
                                        loginDialog.close();
                                        // displayError('You are not authenticated to use File.it');
                                        displayError(JSON.stringify(err));
                                    });
                            }).catch((err) => {
                                loginDialog.close();
                                displayError(JSON.stringify(err));
                                //displayError('Microsoft data fetch issue');

                            });
                        //   localStorage.setItem("PctData",JSON.stringify(data))

                        // We now have a valid access token.
                        //     loginDialog.close();
                        //     setToken(messageFromDialog.result);
                        //     setState( { authStatus: 'loggedIn',
                        //                 headerMessage: 'Get Data' });
                    }
                    else {
                        // Something went wrong with authentication or the authorization of the web application.
                        loginDialog.close();
                        displayError(messageFromDialog.result);
                    }
                });

                loginDialog.addEventHandler(Office.EventType.DialogEventReceived, function (arg: any) {
                    processDialogEvent(arg, setState, displayError);
                });

            }
        }
    );

    const processLoginMessage = (arg: { message: string, type: string }) => {

        let messageFromDialog = JSON.parse(arg.message);


        if (messageFromDialog.status === 'success') {

            // We now have a valid access token.
            loginDialog.close();
            setToken(messageFromDialog.result);
            setState({
                authStatus: 'loggedIn',
                headerMessage: 'Get Data'
            });
        }
        else {
            // Something went wrong with authentication or the authorization of the web application.
            loginDialog.close();
            displayError(messageFromDialog.result);
        }
    };

    const processLoginDialogEvent = (arg) => {
        processDialogEvent(arg, setState, displayError);
    };
};

let logoutDialog: Office.Dialog;
const dialogLogoutUrl: string = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/logout.html';

export const logoutFromO365 = async (setState: (x: AppState) => any,
    displayError: (x: string) => any) => {

    Office.context.ui.displayDialogAsync(dialogLogoutUrl,
        { height: 40, width: 30 },
        (result) => {
            if (result.status === Office.AsyncResultStatus.Failed) {
                displayError(`${result.error.code} ${result.error.message}`);
            }
            else {
                logoutDialog = result.value;
                logoutDialog.addEventHandler(Office.EventType.DialogMessageReceived, processLogoutMessage);
                logoutDialog.addEventHandler(Office.EventType.DialogEventReceived, processLogoutDialogEvent);
            }
        }
    );

    const processLogoutMessage = () => {
        logoutDialog.close();
        setState({
            authStatus: 'notLoggedIn',
            headerMessage: 'Welcome'
        });
    };

    const processLogoutDialogEvent = (arg) => {
        processDialogEvent(arg, setState, displayError);
    };
};

const processDialogEvent = (arg: { error: number, type: string },
    setState: (x: AppState) => any,
    displayError: (x: string) => any) => {

    switch (arg.error) {
        case 12002:
            displayError('The dialog box has been directed to a page that it cannot find or load, or the URL syntax is invalid.');
            break;
        case 12003:
            displayError('The dialog box has been directed to a URL with the HTTP protocol. HTTPS is required.');
            break;
        case 12006:
            // 12006 means that the user closed the dialog instead of waiting for it to close.
            // It is not known if the user completed the login or logout, so assume the user is
            // logged out and revert to the app's starting state. It does no harm for a user to
            // press the login button again even if the user is logged in.
            setState({
                authStatus: 'notLoggedIn',
                headerMessage: 'Welcome'
            });
            break;
        default:
            displayError('Unknown error in dialog box.');
            break;
    }
};

