import * as React from "react";

import { Breadcrumb, IBreadcrumbItem } from "@fluentui/react/lib/Breadcrumb";

export interface BreadCrumbListProps {
  BreadCrumbText: string;
  _onBreadcrumbItemClicked: (data?: any) => void;
}

export default class BreadCrumb extends React.Component<BreadCrumbListProps> {
  render() {
    const { BreadCrumbText,_onBreadcrumbItemClicked } = this.props;
    let BreadcrumbItem: any = [
      {
        text: "Home",
        key: "",
        onClick: () => this.props._onBreadcrumbItemClicked("/")
      },
    ];
    let b = BreadCrumbText.split("/");
    b.pop();

    let count = 1;
    for (let link of b) {
      let FolderURL = b.slice(0, count).join("/") + "/";
      BreadcrumbItem.push({
        text: link,
        key: FolderURL,
         onClick: () => this.props._onBreadcrumbItemClicked(FolderURL),
      });
      count++;
    }
    // const listItems = items.map((item, index) => (
    //     <li className='ms-ListItem' key={index}>
    //         <span className='ms-font-m ms-fontColor-neutralPrimary'>{item.BreadCrumbText}</span>
    //     </li>
    // ));
    return (
      <>
        {BreadcrumbItem.length > 1 ? (
          <Breadcrumb
            items={BreadcrumbItem}
            maxDisplayedItems={2}
            overflowIndex={1}
            ariaLabel=" "
            overflowAriaLabel="More links"
          />
        ) : (
          ""
        )}
      </>
    );
  }
}
