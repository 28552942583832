import axios from 'axios';

//const accessUrl = "http://localhost:8000";
const accessUrl = "https://api.fileit.paharpur.co.in";

export const getDirectoryList = async (pctlAccessToken: string, pctlRefreshToken: string, Prefix: string = ""): Promise<any> => {

    const LoginJson: any = {
        method: "POST",
        url: `${accessUrl}/documents/directory`,
        data: { Prefix: Prefix },
        headers: {
            Authorization: `${pctlAccessToken} ${pctlRefreshToken}`,
        },
    };
    const { data } = await axios(LoginJson);

    return data.data
};

/**
 * Upload Email
 */


export const postEmail = async (pctlAccessToken: string, pctlRefreshToken: string, Prefix: string = ""): Promise<any> => {


    const LoginJson: any = {
        method: "POST",
        url: `${accessUrl}/documents/directory`,
        data: { Prefix: Prefix },
        headers: {
            Authorization: `${encodeURIComponent(pctlAccessToken)} ${encodeURIComponent(pctlRefreshToken)}`,
        },
    };
    const { data } = await axios(LoginJson);
    return data.data
};



export const findEmail = async (pctlAccessToken: string, pctlRefreshToken: string, id: string): Promise<any> => {


    const LoginJson: any = {
        method: "POST",
        url: `${accessUrl}/email/find`,
        data: { id },
        headers: {
            Authorization: `${pctlAccessToken} ${pctlRefreshToken}`,
        },
    };
    const result = await axios(LoginJson);
    return result
};


export const UpdateEmail = async (pctlAccessToken: string, pctlRefreshToken: string, id: string, conversationId: string, accessToken: string, normalizedSubject: string): Promise<any> => {


    const LoginJson: any = {
        method: "POST",
        url: `${accessUrl}/email/update`,
        data: { id, conversationId, accessToken, normalizedSubject },
        headers: {
            Authorization: `${pctlAccessToken} ${pctlRefreshToken}`,
        },
    };
    const result = await axios(LoginJson);
    return result.data


};

export const searchEmail = async (pctlAccessToken: string, pctlRefreshToken: string, searchText: string): Promise<any> => {

    const searchJson: any = {
        method: "POST",
        url: `${accessUrl}/email/search`,
        data: { searchText },
        headers: {
            Authorization: `${pctlAccessToken} ${pctlRefreshToken}`,
        },
    };

    const result = await axios(searchJson);
    return result
};

export const tags = async (pctlAccessToken: string, pctlRefreshToken: string): Promise<any> => {

    const searchJson: any = {
        method: "GET",
        url: `${accessUrl}/tag/tags`,
        data: {  },
        headers: {
            Authorization: `${pctlAccessToken} ${pctlRefreshToken}`,
        },
    };

    const result = await axios(searchJson);
    return result
};




