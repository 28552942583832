import * as React from "react";
import {
  ContextualMenuItemType,
  DirectionalHint,
  IContextualMenuItem,
  IContextualMenuProps,
  IContextualMenuListProps,
} from "@fluentui/react/lib/ContextualMenu";
import { ActionButton } from "@fluentui/react/lib/Button";

import { ISearchBoxStyles, SearchBox } from "@fluentui/react/lib/SearchBox";
import { Icon } from "@fluentui/react/lib/Icon";
import { IRenderFunction } from "@fluentui/react/lib/Utilities";

export type DashboardProps = {
  sendDataToParent: (data: any) => void;
  deleteParams?: any;
  tItems?: any;
};

let s: any = [];
export const TagMenu: React.FunctionComponent<DashboardProps> = (props) => {
  const [selection, setSelection] = React.useState<{ [key: string]: boolean }>({});
  React.useEffect(() => {
    //return () => {
 
    if (props.deleteParams !== "") {
      delete selection[props.deleteParams];
      const findIndex = s.findIndex((a) => a === props.deleteParams);
   
      findIndex !== -1 && s.splice(findIndex, 1);
      setSelection({ ...selection });
      props.sendDataToParent(s);
    } else {
      setSelection({});
    }

 
    // };
  }, [props.deleteParams, props.tItems]);
  const [items, setItems] = React.useState(props.tItems);

  const onToggleSelect = React.useCallback(
    (ev?: React.MouseEvent<HTMLButtonElement>, item?: IContextualMenuItem): void => {
      ev && ev.preventDefault();

      if (Object.values(selection).length === 0) {
        s = [];
        setSelection({});
      }
      const findIndex = s.findIndex((a) => a === item.text);
      findIndex !== -1 && s.splice(findIndex, 1);
      if (selection[item.key] === undefined ? true : !selection[item.key]) {
        s.push(item.key);
       }
      props.sendDataToParent(s);
      if (item) {
        setSelection({ ...selection, [item.key]: selection[item.key] === undefined ? true : !selection[item.key] });
      }
    },
    [selection]
  );
  const menuItems: any = [];
  for (const a of  items.length ===0?props.tItems:items) {
    const p = selection[a.key];
    menuItems.push({ key: a.key, text: a.text, canCheck: true, isChecked: p, onClick: onToggleSelect });
  }

  const onAbort = React.useCallback(() => {
    setItems(menuItems);
  }, []);

  const onChange = React.useCallback((ev: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
    // ev.preventDefault();

    if (ev !== undefined) {
      const filteredItems = menuItems.filter(
        (item) => item.text && item.text.toLowerCase().indexOf(newValue.toLowerCase()) !== -1
      );

      if (!filteredItems || !filteredItems.length) {
        filteredItems.push({
          key: "no_results",
          onRender: () => (
            <div key="no_results" style={filteredItemsStyle}>
              <Icon iconName="SearchIssue" title="No actions found" />
              <span>No actions found</span>
            </div>
          ),
        });
      }

      setItems(filteredItems);
    } else {
      setItems(props.tItems);
    }
  }, []);

  const renderMenuList = React.useCallback(
    (menuListProps: IContextualMenuListProps, defaultRender: IRenderFunction<IContextualMenuListProps>) => {
      return (
        <div>
          <div style={{ borderBottom: "1px solid #ccc" }}>
            <SearchBox
              ariaLabel="Add tag"
              placeholder="Filter actions"
              onAbort={onAbort}
              onChange={onChange}
              styles={searchBoxStyles}
            />
          </div>
          {defaultRender(menuListProps)}
        </div>
      );
    },
    [onAbort, onChange]
  );

  const menuProps: IContextualMenuProps = React.useMemo(
    () => ({
      onRenderMenuList: renderMenuList,
      shouldFocusOnMount: true,
      items: menuItems,
    }),
    [selection, onToggleSelect, items, renderMenuList]
  );
  const onMenuClick = React.useCallback(() => {
    setItems(menuItems);
  }, []);
  return (
    <>
      <ActionButton text="Add Tag" menuProps={menuProps} onMenuClick={onMenuClick} />
    </>
  );
};
const filteredItemsStyle: React.CSSProperties = {
  width: "100%",
  height: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const searchBoxStyles: ISearchBoxStyles = {
  root: { margin: "8px" },
};
